import React from "react";
import "./styles.css";

export default function Home() {
  return (
    <div className="home">
      <div className="title-container">
        <div className="title-left">SUSHI </div>
        <div className="title-right">STORE</div>
      </div>
    </div>
  );
}
